<template>
    <div class="echarts">
        <div class="loading" v-show="isLoading">
                数据加载中...
        </div>
        <div class="shadow" v-show="dataIsEmpty" style="width: 100%;">
            暂无数据
        </div>
        <div class="myChart" ref="myChart" :id="chartId" :style="{visibility: isChartVisible?'visible':'hidden'}"></div>   
    </div>
</template>
<script>
    require('echarts/lib/chart/pie');
    require('echarts/lib/chart/bar');
    require('echarts/lib/component/tooltip');
    require('echarts/lib/component/legend');
    export default {
        data () {
            return {
                chartId:'',
                myChart:null,
                dataIsEmpty:false,
            };
        },
        props:{
            //数据
            option:{
                type:Object,
                default(){
                    return {};
                },
            },
            isLoading:{
                type:Boolean,
                default:false
            }
        },
        created(){
            this.chartId = this.uuid();
        },
        mounted(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.initChart();
                },200);
            })
        },
        methods:{
            initChart(){
                this.myChart=this.echarts.init(document.getElementById(this.chartId));
                this.setOption(this.option);
                this.myChart.resize({    
                    width:"auto",
                    height:"auto"
                })
                window.addEventListener("resize", ()=>{
                    this.myChart.resize({    
                        width:"auto",
                        height:"auto"
                    })
                });
                this.myChart.off('click');
                this.myChart.on('click', (params)=>{
                    this.$emit("clickEvent",params);
                });
                this.myChart.off('mouseover');
                this.myChart.on('mouseover', (params)=>{
                    this.$emit("mouseoverEvent",params);
                });
                this.myChart.off('mouseout');
                this.myChart.on('mouseout', (params)=>{
                    this.$emit("mouseoutEvent",params);
                });
            },
            setOption(option){
                if(this.isObject(option)&&!this.isEmptyObject(option)&&this.hasSeriesKey(option)&&this.isSeriesArray(option)&&!this.isSeriesEmpty(option)&&!this.isSeriesDataEmpty(option)){
                    this.myChart.clear();
                    this.myChart.setOption(option,true);    
                    this.dataIsEmpty=false;
                }else{
                    this.dataIsEmpty=true;
                }
            },
            isObject(option) {
                return Object.prototype.isPrototypeOf(option)
            },
            isEmptyObject(option) {
                return Object.keys(option).length === 0
            },
            hasSeriesKey(option) {
                return !!option['series']
            },
            isSeriesArray(option) {
                return Array.isArray(option['series'])
            },
            isSeriesEmpty(option) {
                return option['series'].length === 0
            },
            isSeriesDataEmpty(option) {
                return option['series'][0].data.length === 0
            },
            uuid(){
                var s = [];
                var hexDigits = "0123456789abcdef";
                for (var i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
                s[8] = s[13] = s[18] = s[23] = "-";
                return s.join("");  
            }
        },
        watch:{
            option:{
                immediate:true,
                deep:true,
                handler(newVal,oldVal){
                    if(this.myChart){
                        if(newVal){
                            this.setOption(newVal);
                        }else{
                            this.setOption(oldVal);
                        } 
                    }
                }
            }
        },
        computed:{
            isChartVisible() {
                return !this.isLoading && !this.dataIsEmpty;
            }
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose();
            this.chart = null;
        },
    }
</script>
<style lang="scss" scoped>
    .echarts{
        height:100%;
        .shadow,.loading{
            height:100%;
            display:flex;
            justify-content:center;
            align-items:center;
            font-size:1rem;
            color:#8590a6;
            font-weight:500;
        }
        .myChart{
            height:100%;
        }   
    }
</style>